import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import MarkdownWrapper from '../../../../components/MarkdownWrapper';
import Layout from '../../../../components/Layout';
import AccordionComponent from '../../../../components/Accordion';
export const _frontmatter = {
  "title": "AWS CodePipeline",
  "path": "/knowledge/tech/aws",
  "date": "2023-03-04T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout title={props.pageContext.frontmatter.title} location={props.path} mdxType="Layout">
      <MarkdownWrapper mdxType="MarkdownWrapper">
        <h1 {...{
          "id": "aws-codepipeline",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h1" {...{
            "href": "#aws-codepipeline",
            "aria-label": "aws codepipeline permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`AWS CodePipeline`}</h1>
        <h2 {...{
          "id": "overview",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#overview",
            "aria-label": "overview permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Overview`}</h2>
        <p>{`The following document is a step-by-step guide on how to create a CI/CD pipeline for an EC2 instance.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/f76f080ab13069d437c68cf88eeebee1/d1442/aws-codepipeline-diagram.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "36.66666666666667%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABFUlEQVQoz21S226DMAzN/79038UrSN3LkNYyoEC5JCQBcjmTQxhbNUtHsR3n+BbmnMMrrCVY2KiTeO+j/7w/4+2PzUgxxsA5C0OBjgh8IDgwzzOWZYGPxAQSR0ncnvBIyoQQqOs6ZvCQekU/qYBJKAyTRFG1KB9P8Flh4BKj0OiGOdwba1GWFZIkAeccTCmFoviClBJaa/R8QZp3SD9alN2M+4Pj/cZxvY1oeoG8nJDmT1w/B1SdCJVlWYa3ywVN04Bt2wbOReifWqaypd4g1Lq3CKBr7+BiCI+porrKsaw62mfLJMzFGRyE5tewTRi2g5Yj1kXCOh/mrWUPs60xfl8K+elkx5b+bvgkIziPQPaf/fpDvgHHDSB5Guo78wAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "diagram",
                "title": "diagram",
                "src": "/static/f76f080ab13069d437c68cf88eeebee1/c1b63/aws-codepipeline-diagram.png",
                "srcSet": ["/static/f76f080ab13069d437c68cf88eeebee1/5a46d/aws-codepipeline-diagram.png 300w", "/static/f76f080ab13069d437c68cf88eeebee1/0a47e/aws-codepipeline-diagram.png 600w", "/static/f76f080ab13069d437c68cf88eeebee1/c1b63/aws-codepipeline-diagram.png 1200w", "/static/f76f080ab13069d437c68cf88eeebee1/d61c2/aws-codepipeline-diagram.png 1800w", "/static/f76f080ab13069d437c68cf88eeebee1/97a96/aws-codepipeline-diagram.png 2400w", "/static/f76f080ab13069d437c68cf88eeebee1/d1442/aws-codepipeline-diagram.png 2538w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "components",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#components",
            "aria-label": "components permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Components`}</h2>
        <p>{`Here are some of the main services/stages for the pipeline.`}</p>
        <h3 {...{
          "id": "codecommit-source",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#codecommit-source",
            "aria-label": "codecommit source permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`CodeCommit (source)`}</h3>
        <p>{`This a source control repository, exactly similar to GitHub. It'll store your project as a repo and trigger the pipeline whenever new changes are pushed to deploy our code automatically.`}</p>
        <blockquote>
          <p parentName="blockquote">{`We're not limited to CodeCommit to store our project`}<a parentName="p" {...{
              "href": "https://docs.aws.amazon.com/prescriptive-guidance/latest/patterns/use-third-party-git-source-repositories-in-aws-codepipeline.html"
            }}>{`, we connect an external GitHub repo with the pipeline`}</a>{`,`}</p>
        </blockquote>
        <h3 {...{
          "id": "codeartifact",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#codeartifact",
            "aria-label": "codeartifact permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`CodeArtifact`}</h3>
        <p>{`A service to store, manage, and publish software packages used in our application during development process.`}</p>
        <p>{`Think of it as a centralized repository where you can store and manage your software packages, such as libraries, frameworks, and other dependencies that your application relies on. Instead of manually downloading and installing these packages on each developer's machine or on different servers, you can use CodeArtifact to store and manage them in one place.`}</p>
        <h3 {...{
          "id": "codebuild-build",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#codebuild-build",
            "aria-label": "codebuild build permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`CodeBuild (build)`}</h3>
        <p>{`Takes care of building, testing, and compiling our application. This stage or service happen before the deploying stage.`}</p>
        <h3 {...{
          "id": "codedeploy-deploy",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#codedeploy-deploy",
            "aria-label": "codedeploy deploy permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`CodeDeploy (deploy)`}</h3>
        <p>{`A service used to deploy our application to EC2 instances, Lambda functions, and on-premises instances.`}</p>
        <blockquote>
          <p parentName="blockquote">{`on-premises instances: `}<a parentName="p" {...{
              "href": "https://docs.aws.amazon.com/codedeploy/latest/userguide/instances-on-premises.html"
            }}>{`any physical device that is not an Amazon EC2 instance that can run the CodeDeploy agent and connect to public AWS service endpoints `}</a></p>
        </blockquote>
        <p>{`You can specify the deployment configuration, deployment group, and revision of your application code using YAML file. CodeDeploy will then handle the deployment process, including copying your code to the target environment, running pre and post deployment tests, and rolling back if there are any issues.`}</p>
        <h3 {...{
          "id": "codepipeline",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#codepipeline",
            "aria-label": "codepipeline permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`CodePipeline`}</h3>
        <p>{`A service that provides continuous delivery and release automation capabilities for your application code. It allows you to build, test, and deploy your code changes automatically`}</p>
        <p>{`CodePipeline will then handle the pipeline execution, including building and testing your code using AWS CodeBuild, deploying your code using AWS CodeDeploy, and automating the release process.`}</p>
        <h2 {...{
          "id": "cdk-code",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#cdk-code",
            "aria-label": "cdk code permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`CDK code`}</h2>
        <h3 {...{
          "id": "steps",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#steps",
            "aria-label": "steps permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Steps`}</h3>
        <ol>
          <li parentName="ol">{`Create an EC2 instance`}
            <ul parentName="li">
              <li parentName="ul">{`Install codedeploy-agent by a user data script. Without codedeploy-agent, CodeDeploy service can't deploy to our EC2 instance`}</li>
            </ul>
          </li>
        </ol>
        <AccordionComponent summaryText="View code part" mdxType="AccordionComponent">
          <pre><code parentName="pre" {...{
              "className": "language-ts"
            }}>{`const createEC2Instance = (parentThis: Construct) => {
  // create VPC in which we'll launch the Instance
  const vpc = new ec2.Vpc(parentThis, 'my-cdk-vpc', {
    ipAddresses: ec2.IpAddresses.cidr(VPC_IP),
    natGateways: 0,
    subnetConfiguration: [
      { name: 'public', cidrMask: 24, subnetType: ec2.SubnetType.PUBLIC },
    ],
  })

  // create a key pair for ssh access
  const keyPair = new ec2.CfnKeyPair(parentThis, 'key-pair', {
    keyName: 'MyKeyPairB',
  })

  const role = new iam.Role(parentThis, 'MyRole', {
    assumedBy: new iam.ServicePrincipal('ec2.amazonaws.com'),
    managedPolicies: [
      iam.ManagedPolicy.fromManagedPolicyArn(
        parentThis,
        'ManagedPolicy',
        'arn:aws:iam::aws:policy/service-role/AWSCodeDeployRole'
      ),
      iam.ManagedPolicy.fromManagedPolicyArn(
        parentThis,
        'ManagedPolicy2',
        'arn:aws:iam::aws:policy/service-role/AmazonEC2RoleforAWSCodeDeploy'
      ),
    ],
  })

  // create the EC2 Instance
  const ec2Instance = new ec2.Instance(parentThis, 'ec2-instance', {
    vpc,
    vpcSubnets: {
      subnetType: ec2.SubnetType.PUBLIC,
    },
    instanceType: ec2.InstanceType.of(
      ec2.InstanceClass.T2,
      ec2.InstanceSize.MICRO
    ),
    machineImage: new ec2.GenericLinuxImage({
      // ubuntu image AMI ID
      // from https://cloud-images.ubuntu.com/locator/ec2/
      'us-east-1': 'ami-0557a15b87f6559cf',
    }),
    keyName: keyPair.keyName,
    role,
    instanceName: 'CdkInstanceVfinal',
  })

  // bash script to quickly install the codedeploy-agent
  // what is used by CodeDeploy to deploy our code to the EC2 instance
  const userDataScript = readFileSync('./lib/startup.sh', 'utf8')
  // add the User Data script to the Instance
  ec2Instance.addUserData(userDataScript)

  return { ec2Instance, vpc }
}
`}</code></pre>
        </AccordionComponent>
        <ol {...{
          "start": 2
        }}>
          <li parentName="ol">{`Create a target group`}</li>
        </ol>
        <AccordionComponent summaryText="View code part" mdxType="AccordionComponent">
          <pre><code parentName="pre" {...{
              "className": "language-ts"
            }}>{`const createTargetGroup = (
  parentThis: Construct,
  vpc: cdk.aws_ec2.IVpc,
  ec2Instance: cdk.aws_ec2.Instance
) => {
  return new NetworkTargetGroup(parentThis, 'target-group', {
    // Has to be TCP in order to work with the load balancer
    protocol: Protocol.TCP,
    port: 80,
    targetType: TargetType.INSTANCE,
    healthCheck: {
      protocol: Protocol.HTTP,
      path: '/health',
    },
    // EC2 instances
    targets: [new targets.InstanceTarget(ec2Instance)],
    vpc,
  })
}
`}</code></pre>
        </AccordionComponent>
        <ol {...{
          "start": 3
        }}>
          <li parentName="ol">{`Create a load balancer`}</li>
        </ol>
        <AccordionComponent summaryText="View code part" mdxType="AccordionComponent">
          <pre><code parentName="pre" {...{
              "className": "language-ts"
            }}>{`const createNlb = (
  parentThis: Construct,
  vpc: cdk.aws_ec2.Vpc,
  targetGroup: cdk.aws_elasticloadbalancingv2.NetworkTargetGroup
) => {
  // create a network load balancer
  const nlb = new NetworkLoadBalancer(parentThis, 'nlb', {
    loadBalancerName: 'MyLoadBalancer-cdk',
    vpc,
    internetFacing: true,
  })

  // create a new listener
  const listener = nlb.addListener('Listener', {
    port: 80,
    protocol: Protocol.TCP,
    defaultTargetGroups: [targetGroup],
  })

  return { nlb, listener }
}
`}</code></pre>
        </AccordionComponent>
        <ol {...{
          "start": 4
        }}>
          <li parentName="ol">{`Create a remote repository in CodeCommit`}</li>
        </ol>
        <AccordionComponent summaryText="View code part" mdxType="AccordionComponent">
          <pre><code parentName="pre" {...{
              "className": "language-ts"
            }}>{`const createCommitRepo = (parentThis: Construct) =>
  new codecommit.Repository(parentThis, 'CodeCommitRepo', {
    repositoryName: 'MyCdkRepo',
  })
`}</code></pre>
        </AccordionComponent>
        <ol {...{
          "start": 5
        }}>
          <li parentName="ol">{`Create a deployment application in CodeDeploy`}
            <ul parentName="li">
              <li parentName="ul">{`An application tells CodeDeploy what to deploy and how to deploy it to EC2/On-Premises, AWS Lambda, and ECS`}</li>
              <li parentName="ul">{`Configure it to deploy to our EC2 instance and use the same load balancer as the EC2 instance`}</li>
            </ul>
          </li>
        </ol>
        <AccordionComponent summaryText="View code part" mdxType="AccordionComponent">
          <pre><code parentName="pre" {...{
              "className": "language-ts"
            }}>{`const application = new codedeploy.ServerApplication(
  parentThis,
  'CodeDeployApplication'
)
`}</code></pre>
        </AccordionComponent>
        <ol {...{
          "start": 6
        }}>
          <li parentName="ol">{`Create a deployment group in CodeDeploy`}
            <ul parentName="li">
              <li parentName="ul">{`Deployment group list an application's deployment groups which include details about a target environment, how traffic shifts during a deployment, and monitoring settings`}</li>
            </ul>
          </li>
        </ol>
        <AccordionComponent summaryText="View code part" mdxType="AccordionComponent">
          <pre><code parentName="pre" {...{
              "className": "language-ts"
            }}>{`const deploymentGroup = new codedeploy.ServerDeploymentGroup(
  parentThis,
  'CodeDeployDeploymentGroup',
  {
    application,
    deploymentGroupName: 'MyDeploymentGroup',
    // adds EC2 instances matching tags
    ec2InstanceTags: new codedeploy.InstanceTagSet({
      // any instance with tags satisfying
      // will match this group
      Name: ['CdkInstanceVfinal'],
    }),
    loadBalancer: codedeploy.LoadBalancer.network(targetGroup),
    // auto creates a role for it
    // role: <someRole>
  }
)
`}</code></pre>
        </AccordionComponent>
        <ol {...{
          "start": 7
        }}>
          <li parentName="ol">{`Create a `}<em parentName="li">{`pipeline`}</em>{` in CodePipeline`}
            <ul parentName="li">
              <li parentName="ul">{`Tell the pipeline to get our project code from CodeCommit repo in the source stage`}</li>
              <li parentName="ul">{`Tell the pipeline to deploy our code through CodeDeploy`}</li>
            </ul>
          </li>
        </ol>
        <AccordionComponent summaryText="View code part" mdxType="AccordionComponent">
          <pre><code parentName="pre" {...{
              "className": "language-ts"
            }}>{`const setupCodePipeline = (
  parentThis: Construct,
  application: cdk.aws_codedeploy.ServerApplication,
  deploymentGroup: cdk.aws_codedeploy.ServerDeploymentGroup,
  commitRepo: cdk.aws_codecommit.Repository
) => {
  const pipeline = new codepipeline.Pipeline(parentThis, 'MyPipeline', {
    // If this is true, our CodeCommit repo in S3 will be encrypted
    // and the deploying stage will fail because it can't decrypt the files
    // couldn't find a solution yet
    crossAccountKeys: false,
  })

  // create source & deploy stages
  const sourceStage = pipeline.addStage({
    stageName: 'Source',
  })
  const deployStage = pipeline.addStage({
    stageName: 'Deploy',
  })

  const sourceOutput = new codepipeline.Artifact('source')

  const sourceStageAction = new codepipelineActions.CodeCommitSourceAction({
    repository: codecommit.Repository.fromRepositoryName(
      parentThis,
      'CodeRepo',
      commitRepo.repositoryName
    ),
    actionName: 'Source',
    // based on my understanding, it stores the project code
    // after pulling/ cloning it into a store(artifact)
    // then the deploy stage takes the artifact or our project code as an input
    output: sourceOutput,
  })

  // set the action of what to happen in this stage
  sourceStage.addAction(sourceStageAction)

  const deployStageAction =
    new codepipelineActions.CodeDeployServerDeployAction({
      deploymentGroup:
        codedeploy.ServerDeploymentGroup.fromServerDeploymentGroupAttributes(
          parentThis,
          'Deployment-group',
          {
            application,
            deploymentGroupName: deploymentGroup.deploymentGroupName,
          }
        ),
      actionName: 'Deploy',
      input: sourceOutput,
    })

  // set the action of what to happen in this stage
  deployStage.addAction(deployStageAction)

  return pipeline
}
`}</code></pre>
        </AccordionComponent>
        <h3 {...{
          "id": "code",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#code",
            "aria-label": "code permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Code`}</h3>
        <AccordionComponent summaryText="View CDK code" mdxType="AccordionComponent">
          <pre><code parentName="pre" {...{
              "className": "language-ts"
            }}>{`import * as cdk from 'aws-cdk-lib'
import * as ec2 from 'aws-cdk-lib/aws-ec2'
import * as targets from 'aws-cdk-lib/aws-elasticloadbalancingv2-targets'
import {
  NetworkLoadBalancer,
  NetworkTargetGroup,
  Protocol,
  TargetType,
} from 'aws-cdk-lib/aws-elasticloadbalancingv2'
import * as codedeploy from 'aws-cdk-lib/aws-codedeploy'
import * as codecommit from 'aws-cdk-lib/aws-codecommit'
import * as codepipeline from 'aws-cdk-lib/aws-codepipeline'
import * as codepipelineActions from 'aws-cdk-lib/aws-codepipeline-actions'
import * as iam from 'aws-cdk-lib/aws-iam'
import { Construct } from 'constructs'
import { readFileSync } from 'fs'

const VPC_IP = '10.0.0.0/16'

const createEC2Instance = (parentThis: Construct) => {
  // create VPC in which we'll launch the Instance
  const vpc = new ec2.Vpc(parentThis, 'my-cdk-vpc', {
    ipAddresses: ec2.IpAddresses.cidr(VPC_IP),
    natGateways: 0,
    subnetConfiguration: [
      { name: 'public', cidrMask: 24, subnetType: ec2.SubnetType.PUBLIC },
    ],
  })

  // create a key pair for ssh access
  const keyPair = new ec2.CfnKeyPair(parentThis, 'key-pair', {
    keyName: 'MyKeyPairB',
  })

  const role = new iam.Role(parentThis, 'MyRole', {
    assumedBy: new iam.ServicePrincipal('ec2.amazonaws.com'),
    managedPolicies: [
      iam.ManagedPolicy.fromManagedPolicyArn(
        parentThis,
        'ManagedPolicy',
        'arn:aws:iam::aws:policy/service-role/AWSCodeDeployRole'
      ),
      iam.ManagedPolicy.fromManagedPolicyArn(
        parentThis,
        'ManagedPolicy2',
        'arn:aws:iam::aws:policy/service-role/AmazonEC2RoleforAWSCodeDeploy'
      ),
    ],
  })

  // create the EC2 Instance
  const ec2Instance = new ec2.Instance(parentThis, 'ec2-instance', {
    vpc,
    vpcSubnets: {
      subnetType: ec2.SubnetType.PUBLIC,
    },
    instanceType: ec2.InstanceType.of(
      ec2.InstanceClass.T2,
      ec2.InstanceSize.MICRO
    ),
    machineImage: new ec2.GenericLinuxImage({
      // ubuntu image AMI ID
      // from https://cloud-images.ubuntu.com/locator/ec2/
      'us-east-1': 'ami-0557a15b87f6559cf',
    }),
    keyName: keyPair.keyName,
    role,
    instanceName: 'CdkInstanceVfinal',
  })

  // bash script to quickly setup the server
  const userDataScript = readFileSync('./lib/startup.sh', 'utf8')
  // add the User Data script to the Instance
  ec2Instance.addUserData(userDataScript)

  return { ec2Instance, vpc }
}

const createTargetGroup = (
  parentThis: Construct,
  vpc: cdk.aws_ec2.IVpc,
  ec2Instance: cdk.aws_ec2.Instance
) => {
  return new NetworkTargetGroup(parentThis, 'target-group', {
    // Has to be TCP in order to work with the load balancer
    protocol: Protocol.TCP,
    port: 80,
    targetType: TargetType.INSTANCE,
    healthCheck: {
      protocol: Protocol.HTTP,
      path: '/health',
    },
    // EC2 instances
    targets: [new targets.InstanceTarget(ec2Instance)],
    vpc,
  })
}

const createNlb = (
  parentThis: Construct,
  vpc: cdk.aws_ec2.Vpc,
  targetGroup: cdk.aws_elasticloadbalancingv2.NetworkTargetGroup
) => {
  // create a network load balancer
  const nlb = new NetworkLoadBalancer(parentThis, 'nlb', {
    loadBalancerName: 'MyLoadBalancer-cdk',
    vpc,
    internetFacing: true,
  })

  // create a new listener
  const listener = nlb.addListener('Listener', {
    port: 80,
    protocol: Protocol.TCP,
    defaultTargetGroups: [targetGroup],
  })

  return { nlb, listener }
}

const setEc2SecurityGroup = (
  parentThis: Construct,
  vpc: cdk.aws_ec2.Vpc,
  ec2Instance: cdk.aws_ec2.Instance
) => {
  // create a Security Group for the Instance
  const SecurityGroup = new ec2.SecurityGroup(parentThis, 'ec2-sg', {
    vpc,
    allowAllOutbound: true,
  })

  // enable SSH
  SecurityGroup.addIngressRule(
    ec2.Peer.anyIpv4(),
    ec2.Port.tcp(22),
    'allow SSH access from anywhere'
  )

  // enable HTTP for load balancer
  SecurityGroup.addIngressRule(
    ec2.Peer.ipv4(VPC_IP),
    ec2.Port.tcp(80),
    'allow HTTP traffic from anywhere'
  )

  ec2Instance.addSecurityGroup(SecurityGroup)

  return SecurityGroup
}

const createCommitRepo = (parentThis: Construct) =>
  new codecommit.Repository(parentThis, 'CodeCommitRepo', {
    repositoryName: 'MyCdkRepo',
  })

const setupCodeDeployApp = (
  parentThis: Construct,
  targetGroup: cdk.aws_elasticloadbalancingv2.NetworkTargetGroup
) => {
  const application = new codedeploy.ServerApplication(
    parentThis,
    'CodeDeployApplication'
  )

  // create deployment group
  const deploymentGroup = new codedeploy.ServerDeploymentGroup(
    parentThis,
    'CodeDeployDeploymentGroup',
    {
      application,
      deploymentGroupName: 'MyDeploymentGroup',
      // adds EC2 instances matching tags
      ec2InstanceTags: new codedeploy.InstanceTagSet({
        // any instance with tags satisfying
        // will match this group
        Name: ['CdkInstanceVfinal'],
      }),
      loadBalancer: codedeploy.LoadBalancer.network(targetGroup),
      // auto creates a role for it
      // role: <someRole>
    }
  )

  return { application, deploymentGroup }
}

const setupCodePipeline = (
  parentThis: Construct,
  application: cdk.aws_codedeploy.ServerApplication,
  deploymentGroup: cdk.aws_codedeploy.ServerDeploymentGroup,
  commitRepo: cdk.aws_codecommit.Repository
) => {
  const pipeline = new codepipeline.Pipeline(parentThis, 'MyPipeline', {
    // If this is true, our CodeCommit repo in S3 will be encrypted
    // and the deploying stage will fail because it can't decrypt the files
    // couldn't find a solution yet
    crossAccountKeys: false,
  })

  // create source & deploy stages
  const sourceStage = pipeline.addStage({
    stageName: 'Source',
  })
  const deployStage = pipeline.addStage({
    stageName: 'Deploy',
  })

  const sourceOutput = new codepipeline.Artifact('source')

  const sourceStageAction = new codepipelineActions.CodeCommitSourceAction({
    repository: codecommit.Repository.fromRepositoryName(
      parentThis,
      'CodeRepo',
      commitRepo.repositoryName
    ),
    actionName: 'Source',
    // based on my understanding, it stores the project code
    // after pulling/ cloning it into a store(artifact)
    // then the deploy stage takes the artifact or our project code as an input
    output: sourceOutput,
  })

  // set the action of what to happen in this stage
  sourceStage.addAction(sourceStageAction)

  const deployStageAction =
    new codepipelineActions.CodeDeployServerDeployAction({
      deploymentGroup:
        codedeploy.ServerDeploymentGroup.fromServerDeploymentGroupAttributes(
          parentThis,
          'Deployment-group',
          {
            application,
            deploymentGroupName: deploymentGroup.deploymentGroupName,
          }
        ),
      actionName: 'Deploy',
      input: sourceOutput,
    })

  // set the action of what to happen in this stage
  deployStage.addAction(deployStageAction)

  return pipeline
}

export class CdkStack extends cdk.Stack {
  constructor(scope: cdk.App, id: string, props?: cdk.StackProps) {
    super(scope, id, props)

    const { ec2Instance, vpc } = createEC2Instance(this)
    const targetGroup = createTargetGroup(this, vpc, ec2Instance)
    createNlb(this, vpc, targetGroup)

    setEc2SecurityGroup(this, vpc, ec2Instance)

    // CodeCommit
    const commitRepo = createCommitRepo(this)

    // CodeDeploy
    const { application, deploymentGroup } = setupCodeDeployApp(
      this,
      targetGroup
    )

    // CodePipeline
    setupCodePipeline(this, application, deploymentGroup, commitRepo)

    // outputs the repo URL in the terminal
    new cdk.CfnOutput(this, 'apiUrl', {
      value: commitRepo.repositoryCloneUrlHttp,
    })
  }
}
`}</code></pre>
        </AccordionComponent>
        <h3 {...{
          "id": "demo",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#demo",
            "aria-label": "demo permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Demo`}</h3>
        <ol>
          <li parentName="ol">{`Go to your poject folder and push to the repository clone URL, otherwise it'll be empty and you won't be able to deploy`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`git push https://git-codecommit.us-east-1.amazonaws.com/v1/repos/SuperRepo -all`}</inlineCode></li>
              <li parentName="ul">{`You can clone `}<a parentName="li" {...{
                  "href": "https://github.com/flacial/codedeploy-aws-nodejs"
                }}>{`my demo express.js project`}</a>{` and then push it to the CodeCommit repo`}</li>
            </ul>
          </li>
          <li parentName="ol">{`Go to the CodePipeline console and notice how it shows a triggered pipeline`}</li>
          <li parentName="ol">{`Once the pipeline finish executing successfully, go the EC2 instance URL and test it out`}</li>
        </ol>
        <h3 {...{
          "id": "potential-issues-you-might-encounter",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#potential-issues-you-might-encounter",
            "aria-label": "potential issues you might encounter permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Potential issues you might encounter`}</h3>
        <ul>
          <li parentName="ul">{`CodeDeploy: if events are all pending, it means the codedeploy-agent wasn't installed in the EC2 instance`}</li>
          <li parentName="ul">{`CodeDeploy: If AllowTraffic event fails, it means the EC2 instance isn't healthy, verify the target group and load balancer are configured correctly`}</li>
          <li parentName="ul">{`CodePipeline: if the source stage fails, confirm you the codecommit repo isn't empty`}</li>
          <li parentName="ul">{`CodeDeploy(EC2): if codedeploy-agent isn't installed, install it with a `}<a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/user-data.html"
            }}>{`user data script`}</a>{` (ec2)`}</li>
          <li parentName="ul">{`EC2: if the new changes aren't deployed or seem like that, this probably means the node server process wasn't killed and therefore when trying to start a new server, it'll throw an error saying that port 80 is already used. So, make sure to kill all node processes first.`}</li>
          <li parentName="ul">{`CodeDeploy: if you're stuck in AllowTraffic or BlockTraffic, this is normal because these take a few minutes`}</li>
          <li parentName="ul">{`CodeDeploy: if you're stuck in AllowTraffic, this means there's something wrong with the target group. It could be that the target group isn't connected to a load balancer or your EC2 instance is an healthy`}</li>
        </ul>
        <h3 {...{
          "id": "important-codedeploy-events",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#important-codedeploy-events",
            "aria-label": "important codedeploy events permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Important CodeDeploy events`}</h3>
        <ul>
          <li parentName="ul">{`BlockTraffic: in this event, CodeDeploy unregister the EC2 instance from the target group so it doesn't receive any requests
Block`}</li>
          <li parentName="ul">{`AllowTraffic: in this event, CodeDeploy register the EC2 instance and waits until it's considered `}<strong parentName="li">{`healthy`}</strong></li>
        </ul>
        <h2 {...{
          "id": "references",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#references",
            "aria-label": "references permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`References`}</h2>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/codedeploy/latest/userguide/codedeploy-agent-operations-install-ubuntu.html"
            }}>{`Install CodeDeploy agent for Ubuntu`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/user-data.html"
            }}>{`Running commands on Linux`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://debugthis.dev/cdk/2020-30-06-aws-cdk-code-pipeline/"
            }}>{`What I followed to create the above CodePipeline (written in Python but easy to translate)`}</a></li>
          <li parentName="ul">{`A lot of ChatGPT!`}</li>
        </ul>
      </MarkdownWrapper>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      